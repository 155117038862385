// Here you can add other styles

.nav-link {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.nowrap-1cpt {
  white-space: nowrap;
  width: 1%;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.pointer {
  cursor: pointer;
}

.users-search-form {
  margin: -10px 0 0 0;
  > * {
    margin-top: 10px;
  }
}

button.reset-password {
  background: rgb(255,0,73);
  background: linear-gradient(90deg, rgba(255,0,73,1) 0%, rgba(235,0,139,1) 100%);
  color: white;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;  
}

.mb-logo {
  margin-bottom: ($spacer * 5) !important;
}